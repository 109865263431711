<template>
    <router-view></router-view>
</template>
<script>
    import parkStoreModule from './parkStoreModule.js';
    import store from '@/store';
    import { onUnmounted } from '@vue/composition-api';

    export default {
        setup() {
            const PARK_APP_STORE_MODULE_NAME = 'app-park';

            // Register module
            if (!store.hasModule(PARK_APP_STORE_MODULE_NAME))
                store.registerModule(PARK_APP_STORE_MODULE_NAME, parkStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(PARK_APP_STORE_MODULE_NAME)) store.unregisterModule(PARK_APP_STORE_MODULE_NAME);
            });
        },
    };
</script>
